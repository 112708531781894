// const Url = "https://client.securefx.net";
// const Url = "https://my.lunarwolf.ai";
const Url=".";

var fullURL = window.location.href;
var baseURL = fullURL.split("/").slice(0, 3).join("/");

// const IsApprove = {
//   is_app: 1,
//   auth: "857274-08564a-566a64"  ,
//   user_id: "962",
//   is_crm: 1,
// };
const IsApprove = "";
export { Url, IsApprove,baseURL };
